


























import { Component, Vue, Emit } from "vue-property-decorator";
import { DocumentsApi, Document } from "@/store/documents-api";
import CForm from "@/common/components/CForm.vue";
import { required, requiredIf } from "@/common/validators/rules";

@Component({ components: { CForm } })
export default class DocumentsForm extends Vue {
    documentsStore = new DocumentsApi();
    validators = { required, requiredIf };
    dialog = false;
    loading = false;
    item = {} as Document;

    get formTitle() {
        return !this.item.id ? "New Document" : "Edit Document";
    }

    open(item: Document) {
        this.item = Object.assign({}, item);
        this.dialog = true;
        this.$nextTick(() => (this.$refs.form as any).resetValidation());
    }

    close() {
        this.dialog = false;
    }

    @Emit("updated")
    save() {
        if (!(this.$refs.form as any).validate()) {
            return;
        }

        this.loading = true;
        return this.documentsStore
            .save({ document: this.item })
            .then(() => (this.dialog = false))
            .finally(() => (this.loading = false));
    }
}
