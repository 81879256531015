

































import { Component, Vue } from "vue-property-decorator";
import { DocumentsApi, Document } from "@/store/documents-api";
import CDeleteConfirm from "@/common/components/CDeleteConfirm.vue";
import DocumentsForm from "./DocumentsForm.vue";

@Component({ components: { CDeleteConfirm, DocumentsForm } })
export default class Documents extends Vue {
    documentStore = new DocumentsApi();
    documents: Document[] = [];
    loading = false;

    mounted() {
        this.get();
    }

    get() {
        this.loading = false;

        return this.documentStore
            .getAll()
            .then((res) => (this.documents = res.data))
            .then(() => (this.loading = false));
    }

    upsertItem(item: Document) {
        (this.$refs.upsert as DocumentsForm).open(item);
        return false;
    }

    confirmDelete(item: Document) {
        (this.$refs.delete as CDeleteConfirm).open(item);
        return false;
    }

    deleteConfirmed(item: Document) {
        this.loading = true;
        this.documentStore
            .delete({ documentId: item.id })
            .then(() => this.get())
            .finally(() => (this.loading = false));
    }
}
