import { axios } from "./axios";
import FileUtils from "@/common/utils/fileUtils";

export class DocumentsApi {
    getAll() {
        return axios.get<Document[]>("/documents");
    }

    save({ document }: { document: Document }) {
        const formData = FileUtils.objectToFormData(document);
        if (!!document.id && document.id > -1) {
            return axios.put("/documents", formData);
        } else {
            return axios.post("/documents", formData);
        }
    }

    delete({ documentId }: { documentId: number }) {
        return axios.delete(`/documents/${documentId}`);
    }
}

export interface Document {
    id: number;
    name: string;
    restricted: boolean;
    file: Blob;
    mediaUri: string;
}
